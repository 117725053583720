import Address from './Address';

import React from 'react';

export default function OrderDetails(props) {

	let address;

	if (props.order.shipping_address)
	{
		address = <Address address={props.order.shipping_address} />;
	}
	else if (props.order.name)
	{
		address = <span>No Shipping Address Set</span>;
	}

    let tags;
    if (props.order.tags) {
        tags = <p>Tags : {props.order.tags}</p>
    }

    let customer_tags;
    if (props.order.customer_tags) {
        customer_tags  = <p>Customer Tags: {props.order.customer_tags} </p>
    }
    
    let discount;
    if (props.order.discount_code) {
        discount = <p>Discount Code : {props.order.discount_code}</p>
    }

	return (
		<div>
			{props.order.name}
			{address}
            {customer_tags}
            <br />
            {tags}
            {discount}
		</div>
	);
}
