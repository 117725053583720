import React from 'react';
import { Button } from '@shopify/polaris';

export default function Buttons(props) 
{
	return(
        <div id="buttons">
        <Button className="function-button left-button" id="reset" onClick={(e) => props.onReset(e) } >Reset</Button>
        </div>

	);
}
