
class Completer
{
    constructor(settings, api, sounds ) 
    {
        this.settings = settings;
        this.api = api;
        this.sounds  = sounds;
    }

    markComplete = async (id)  => {
        if (this.settings.add_tag) 
        {
            await this.api.AddTag(id);
        }

        if (this.settings.sounds) 
        {
            this.sounds.PlayBell();
        }
    }
}

export default Completer;
