import Config from './Config';
import axios from 'axios';
import { getSessionToken } from "@shopify/app-bridge-utils";
import { Redirect } from '@shopify/app-bridge/actions';
import { orderGql, parseOrder, lineItemsGql, parseLineItems } from './OrderGql';
import fileDownload from 'js-file-download';

class Api {

	constructor(app) 
    {
        this.baseUrl = Config.url;
        this.app = app;
        this.settingsUrl = this.baseUrl + Config.settingsPath;

        this.axiosInstance = axios.create();
 		this.axiosInstance.interceptors.request.use(
			function (config) {
				return getSessionToken(app)  // requires an App Bridge instance
					.then((token) => {
						config.headers['Authorization'] = `Bearer ${token}`;
                      //  config.headers['X-CSRF-Token'] = csrfToken;
						return config;
					});
			}
		);

    }

    async GetOrder(orderNo) 
    {
        console.log("get order ", orderNo);
        const encodedOrderNo = encodeURIComponent(orderNo); 
        
        const response = await this.axiosInstance.post(
            '/api/orders/' + encodedOrderNo, 
            { query: orderGql, variables: { orderno: encodedOrderNo} })
            .catch(err => this.checkAuth(err.response));

      	console.log(response);
        const order = parseOrder(orderNo, response.data.data.orders);

        let moreItems = order.moreLineItems;
       
        while (moreItems) {
            const liResponse = await this.axiosInstance.post(
            '/api/orders/' + encodedOrderNo, 
                { query: lineItemsGql, variables: { orderId: order.gqlId, after: order.lastLiCursor } })
            
            .catch(err => this.checkAuth(err.response));

            parseLineItems(order, liResponse.data.data.order);

            moreItems = order.moreLineItems;
        }

        return order;

    }

    async GetLineItems(order) {

    }

    async GetPdf(order) {
        const response = await this.axiosInstance.post('/api/pdf', order);

        console.log(response);
        fileDownload(response.data, `return-${order.id}.pdf`);
    }

    checkAuth = (response) => {

		if (response.status === 403) {
			const authUrlHeader = response.headers['x-shopify-api-request-failure-reauthorize-url'];
            const redirect = Redirect.create(this.app);
            console.log("redirect to ", authUrlHeader);
            redirect.dispatch(
               Redirect.Action.REMOTE,
               authUrlHeader.startsWith("/")
                   ? `https://${window.location.host}${authUrlHeader}`
                   : authUrlHeader
             );
		}
  };
}

export default Api;
