import React from 'react';

export default function Controls(props) {

	return(
		<div id="controls">
		<div id="barcode">
		<input type="text" name="barcode-text" ref={props.barcodeRef} 
         autoComplete="off" onKeyPress={(e) => props.onKeyPress(e) } />
		</div>
	</div>);
}
