import React from 'react';

export default function NoteAndTracking(props) {

    const  handleInputChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        const newOrder = props.order;
        newOrder[name] = value;

        props.handleOrderUpdate(newOrder);
    }

    if (props.stage !== "LineItems" || !props.order) {
        return null 
    }

	return(
		<div id="detail-controls">
            <span>Return Tracking</span><input type="text" name="return_tracking" onChange={(e) => handleInputChange(e)} value={props.order.return_tracking} />
            <span>Note</span><input type="text" name="note" onChange={(e) => handleInputChange(e) } value={props.order.note} />
		</div>
	);
}
