import React from 'react';
import { Modal } from '@shopify/polaris';

export default function Error(props) 
{
    return(
        <Modal open={true} 
            onClose={props.onClick} 
            title={props.title}
            primaryAction={ { content: 'OK', onAction: props.onClick } }
        >
            <Modal.Section>
            <p>{props.message}</p>
            </Modal.Section>
        </Modal>
    );
}
