import React, { Component } from 'react';
import './App.css';
import Config from './Config';
import '@shopify/polaris/dist/styles.css';
import Main from './Main';
import Api from './Api';
import { AppProvider } from '@shopify/polaris';
import  createApp from '@shopify/app-bridge';
import translations from '@shopify/polaris/locales/en.json';

class App extends Component {

	constructor(props)
    {
		super(props);

        console.log(window.location.toString());
        const params = new URLSearchParams(document.location.search.substring(1));
        const host = params.get("host"); 

        // shopOrigin: Config.shop, 
        const app = createApp({ apiKey: Config.apiKey, host: host, forceRedirect: true });

	    console.log("shop = ", app.store, app);

		this.Api = new Api(app);
    }

    render() {

		return (
            <AppProvider i18n={translations} >
               <Main api={ this.Api } settings = { {} } />
            </AppProvider>
		);
	}
}

export default App;
