import React from 'react';

export default function LineItem(props) {

	const item = props.item;

	let toGoText = null;

    let className = item.status;

	if (item.toGo) {
		toGoText = <span className="toGo">{item.toGo} more to pack</span>;
        className="partial";
	}

	let missingText = null;

	if (item.missing) {
		missingText = <span className="missingText"> - BARCODE MISSING!</span>;
	}

    let image = "";
    if (item.img_src) {
        image = <img className="li-img" alt={item.name} src={ item.img_src } />
    }


	return (
		   <li className={className} >{ image }{item.quantity} x {item.name} { item.sku } {missingText} <span>: {item.received} </span>{ toGoText }</li>
	);
}
