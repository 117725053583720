import React from 'react';
  
export default function AddressLine(props) {
	if (!props.value) {
		return null;
	}

	return(
		<span>{props.value} <br /></span>
	);
}
