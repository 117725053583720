import React from 'react'
import AddressLine from './AddressLine'

export default class Address extends React.Component  {

	render() {
		const address = this.props.address;

		return(
                  <div>
		    <AddressLine value={address.name} />
                    <AddressLine value={address.address1} />
	            <AddressLine value={address.address2} /> 
		    <AddressLine value={address.province} />
		    <AddressLine value={address.zip} />
		    <AddressLine value={address.country} />
            <AddressLine value={address.phone} />
	     	 </div>
		);
	}
}

