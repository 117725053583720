import {Howl} from 'howler';

class Sounds
{
    constructor()
    {
        try 
        {
            this.errorSound = new Howl({ src: ['/sounds/error.mp3' ] });
            this.bellSound = new Howl({ src: ['/sounds/bell.mp3' ] });
            this.clickSound = new Howl({ src: ['/sounds/click.mp3'] });
        }
        catch (ex) 
        {
            console.log("unable to use sounds", ex);
        }
    }

    PlayBell = () => {
        if (this.bellSound) 
        {
            this.bellSound.play();
        }
    }

    PlayError = () => {
        if (this.errorSound)
        {
            this.errorSound.play();
        }
    }

    PlayClick = () => {
        if (this.clickSound) 
        {
            this.clickSound.play();
        }
    }
}

export default Sounds;
