import ValidationResult from './ValidationResult';

class LineItemsStage {

    code  = 'LineItems';
    title = 'Scan Items';
    breadcrumb = 'Items Scanned';

    async process(order, barcode) 
    {

        let result = ValidationResult.success();
        let possible = [];

        let lineItems = order.line_items;

        for (var i = 0 ; i < lineItems.length; i++) {

            let lineItem = lineItems[i];
            lineItem.received = lineItem.received || 0;

            let liBarCode = this.getBarCode(lineItem);

            if ((liBarCode === barcode ) 
                && (lineItem.quantity > lineItem.received)) {

                lineItem.received++;

                return {order, result};

            } else if (lineItem.quantity > lineItem.received && lineItem.requires_shipping ) {

                possible.push(liBarCode);
            }
        }

        // item not found so shipping the wrong thing!

        result = ValidationResult.error( "Incorrect Item Scanned","Scanned barcode was " + barcode + " . The barcodes of products still to pack are: " + possible.join());

        return { order, result };

    }

    orderComplete(lineItems) {

        for (var i = 0 ; i < lineItems.length; i++) {
            let lineItem = lineItems[i];
            if (!lineItem.requires_shipping) 
            {
                // not needed
            } 
            else if (lineItem.received === lineItem.quantity ) 
            {
                // all done 
            }
            else 
            {
                return false;
            }
        }

        return true;

    }

    // can be overridden if the store uses sku instead of barcode
    getBarCode(lineItem) 
    {
        return lineItem.barcode;
    }


    next() 
    {
        // to be overridden
    }


}

export default LineItemsStage;


