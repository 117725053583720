import ValidationResult from './ValidationResult';

class EmptyStage {

    code  = 'Empty';
    title = 'Scan Order'
    breadcrumb = 'Order Found';

    constructor(api) {
        this.Api = api;
    }

    process = async (order, barcode) => 
    {
        order = {};

        try 
        {
            order = await this.Api.GetOrder(barcode);
            this.next();
        }
        catch (ex) 
        {
            const result = ValidationResult.error('Order Not Found', ''); 
            return { order, result };
        }

        if (!order.found) {
            const result = ValidationResult.error('Order Not Found', ''); 
            return { order, result };
        }

        const result = ValidationResult.success();

        return { order, result };

    }

    next() {
        // to be overridden
    }

    // to be overridden
    validate(order) {
        return ValidationResult.success();
    }


}

export default EmptyStage;


