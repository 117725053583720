import React, { Component } from 'react';
import Buttons from './Buttons';
import Breadcrumbs from './Breadcrumbs';
import Status from './Status';
import Controls from './Controls';
import NoteAndTracking from './NoteAndTracking';
import OrderDetails from './OrderDetails';
import LineItems from './LineItems';
import Stages from './Stages';
import Error from './Error';
import Completer from './Completer';
import Sounds from './Sounds';
import { Button, Page, Card } from '@shopify/polaris';

class Main extends Component {

	constructor(props)
    {
		super(props);

        this.Api = props.api;
        this.Stages= new Stages(props.settings, props.api);
        this.barcodeRef = React.createRef();
        
        const completer = new Completer(props.settings, this.Api, this.Sounds); 
    
        this.Stages.complete = async () => {
             await completer.markComplete(this.state.order.id);
        }

        this.Sounds = new Sounds();

		this.state = {
            error: null, 
            carrier: "",
            settings: props.settings, 
			order: {}, 
            stage: this.Stages.current(),
            breadcrumbs : this.Stages.breadcrumbs(), 
            currentStageIndex : this.Stages.currentStage
		};

	}

    componentDidMount() {
        this.barcodeRef.current.focus();
    }

    handleKeyPress(e)
    {
        const keycode = e.keyCode || e.which;
        const barcode = e.target.value;

        if (keycode===13 && barcode !== "") {
            this.processBarCode(barcode);
            e.target.value = "";
            this.barcodeRef.current.focus();
        }
    }

    handleReset(e) 
    {
        this.Stages.reset();

        this.setState({ 
            stage : this.Stages.current(), 
            order : {}, 
            currentStageIndex : 0,
            error: null  });

        this.barcodeRef.current.focus();
    }

    handleCarrierChange(e) 
    {
        this.setState({ carrier : e.target.value });
    }

    handleErrorOk(e) {
        if (this.state.settings.reset_on_error) {
            this.handleReset();
        }
        this.setState({ error : null }, () => this.barcodeRef.current.focus());
    }

    handleOrderUpdate(order) {
        this.setState({order : order});
    }

    handlePdf = async () => {
        await this.Api.GetPdf(this.state.order);
    }

    async processBarCode(barcode)
    {
        if (!this.Stages) {
            return;  // settings have not loaded
        }

        this.Sounds.PlayClick();

        const response =  await this.state.stage.process(this.state.order, barcode);

        let order = this.state.order;

        if (!response.result.valid) 
        {
            this.Sounds.PlayError();
            this.setState({ error : response.result });
            if (this.state.stage.code !== "LineItems")
            {
                this.Stages.reset();
            }
        }
        else 
        {
            order = response.order;
            if (this.state.stage.isFinal) 
            {
                order = {};
            }
        }

        this.setState({ 
            order : order, 
            stage : this.Stages.current(),
            currentStageIndex: this.Stages.currentStage
        }, () => this.barcodeRef.current.focus());
    }


	render() {

        if (!this.state.stage) {
            return null;
        }

        let lineItems = null;
        let doneButton;
        if (this.state.stage.code === "LineItems")
        {
            lineItems = <LineItems order={this.state.order} />;
            doneButton = <Button primary onClick={this.handlePdf} >Done</Button>
        }

        let error = null;
        if (this.state.error) {
            error = <Error 
                title={this.state.error.title} 
                message={this.state.error.message}
                onClick={ e => this.handleErrorOk(e) }
                />
        }

		return (
            <Page>
                <Card>
                    <Card.Section>
                        {error} 
                        <Buttons onReset={e => this.handleReset(e) } showLocation={this.state.settings.showLocation} />
                        <Status value={this.state.stage.title} />
                        <Breadcrumbs stages={this.state.breadcrumbs} index={this.state.currentStageIndex} />
                        <OrderDetails order={this.state.order} />
                        <Controls 
                            barcodeRef={ this.barcodeRef }
                            onKeyPress={ (e) => this.handleKeyPress(e) } />
                        {lineItems}
                        <NoteAndTracking 
                            stage={this.state.stage.code} 
                            order={this.state.order} 
                            handleOrderUpdate={ (e) => this.handleOrderUpdate(e) } /> 
                        { doneButton }
                    </Card.Section>
                </Card>
            </Page>
        );
	}
}

export default Main;
