class ValidationResult
{
    valid = true;
    title = "";
    message = "";

    static success() {
        return new ValidationResult();
    }

    static error(title, message)
    {
        let result = new ValidationResult();
        result.valid = false;
        result.title = title;
        result.message = message;

        return result;
    }


}

export default ValidationResult;
