import EmptyStage from './EmptyStage';
import LineItemsStage from './LineItemsStage';
import OrderValidation from './OrderValidation';

class Stages {

    constructor(settings, api) {

        this.settings = settings;

        this.stageList = [];

        let emptyStage = new EmptyStage(api);

        const orderValidation = new OrderValidation(settings);
        emptyStage.validate = (order) => { return orderValidation.validate(order); };

        this.addStage(emptyStage);

        let liStage = new LineItemsStage();

        if (settings.use_sku) 
        {
            liStage.getBarCode = (li) => { return li.sku; };
        }

        this.addStage(liStage);

        this.currentStage = 0;

    }

    addStage(stage) {
        stage.next = () => { this.next(); }; 
        this.stageList.push(stage);
    }

    current() {
        return this.stageList[this.currentStage];
    }

    next() {
        if (this.currentStage === 0) {
            this.currentStage++;
        }
    }

    breadcrumbs() {
        return this.stageList.map(x => x.breadcrumb);
    }

    reset() {
        this.currentStage = 0;
    }

    complete() {
        // to be overridden
    }


}

export default Stages;
