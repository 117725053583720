import React from 'react';

export default function Breadcrumbs(props) {

    let stages = [];

    for (let i = 0; i < props.stages.length ; i++) {
        let thisClass = (i < props.index) ? "tag grey complete" : "tag grey";
        stages.push(<li className= {thisClass} key={props.stages[i]} >{props.stages[i]}</li>);
    }

    return(
        <ul id="breadcrumbs">
        {stages}
        </ul>
    );
}
