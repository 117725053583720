import React from 'react';
import LineItem from './LineItem';

export default function LineItems(props) {

    const lineItems = props.order.line_items || [];
    let lineItem;

    for (let i = 0 ; i < lineItems.length; i++) {

        lineItem = lineItems[i];
        lineItem.toGo = null;

        lineItem.received = lineItem.received || 0;

        if ( !lineItem.requires_shipping ) {
            lineItem.status = "complete";
        } else if (lineItem.quantity === lineItem.received ) {
            lineItem.status = "complete";
        } else if (lineItem.barcode === "") {
            lineItem.status = "missing";
        } else if (lineItem.barcode === "missing-variant") {
            lineItem.status = "missing";
        } else {
            lineItem.status="";
            if (lineItem.received > 0) {
                lineItem.toGo = lineItem.quantity - lineItem.received;
            }
        }

    }

    return (
        <ul id="line-items">
        { lineItems.map(item => { return <LineItem item={item} key={ item.id } />; }) }
        </ul>
    );
}
