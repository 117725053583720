import ValidationResult from './ValidationResult';

class OrderValidation {

    constructor(settings) 
    {
        this.settings = settings;
    }

    validate(order) 
    {
        if (!this.settings.allow_checked) 
        {
            if (this.hasCheckedTag(order)) 
            {
                return ValidationResult.error('Already Checked', 'Order Has Already Been Checked');
            }
        }

        if (!this.settings.allow_fulfilled) 
        {
            if (order.fulfillment_status === "fulfilled" ) 
            {
                return ValidationResult.error("Unable to ship", "Order has already been fulfilled");
            }
        }

        return ValidationResult.success();
    }


    hasCheckedTag(order) {

        const tags = order.tags.split(",");
        let i;

        for (i = 0; i < tags.length; i++) 
        {
            if (tags[i].trim().startsWith("checked")) 
            {
                return true;
            }
        }

        return false;

    }      


}

export default OrderValidation;
