export const orderGql = `
query OrderQuery($orderno :String, $after :String)  {
  orders(first:5, query:$orderno, sortKey: ORDER_NUMBER, reverse:true, after: $after) {
    edges {
      cursor,
      node {
        id,
        legacyResourceId,
        name,
        closedAt,
        cancelledAt,
        createdAt,
        displayFulfillmentStatus,
        displayFinancialStatus,
        note,
        tags,
        discountCode,
        shippingAddress {
          address1, address2, city, name, province, zip, country, countryCodeV2
        },
        customer { phone, tags }, 
        billingAddress {
          name
        }, 
       lineItems (first:50) {
          pageInfo {
            hasNextPage
          },
          edges {
            cursor,
            node {
              id,
              requiresShipping,
              quantity,
              fulfillableQuantity,
              name,
              image {
                transformedSrc(maxHeight:80)
              },
              variant {
                id, barcode, sku, legacyResourceId
              } 
            }
          }
        }
      }
    }
  }
}`;       

export const lineItemsGql = `
query OrderQuery($orderId :ID!, $after :String)  {
  order(id: $orderId) {
       lineItems (first:10, after: $after) {
          pageInfo {
            hasNextPage
          },
          edges {
            cursor,
            node {
              id,
              requiresShipping,
              quantity,
              fulfillableQuantity,
              name,
              image {
                transformedSrc(maxHeight:80)
              },
              variant {
                id, barcode, sku, legacyResourceId
              } 
            }
          }
        }
      }
 }`;       

const processLineItem = (li) => {
    return {
        id : li.node.id, 
        variant_id : (li.node.variant || {}).id, 
        barcode : (li.node.variant || { barcode: "missing-variant" }).barcode, 
        sku : (li.node.variant || {}).sku, 
        name : li.node.name, 
        quantity : li.node.quantity, 
        requires_shipping : li.node.requiresShipping, 
        img_src: (li.node.image || {}).transformedSrc
    };
};

export const parseOrder = (orderno, orders) => {

    let order;

    try {

    const orderNode = orders.edges.find(o => { return o.node.name.includes(orderno) });

    console.log(orderNode);

    if (!orderNode) {
        return { found: false };
    }

    order = orderNode.node;
    const lineItems = order.lineItems.edges;

    return {
            order_number : order.name,
            order_date : order.createdAt,
            found: true,
            moreLineItems: order.lineItems.pageInfo.hasNextPage,
            lastLiCursor: lineItems[lineItems.length - 1].cursor,
            id : order.legacyResourceId,
            gqlId : order.id,
            name : order.name,
            financial_status : order.displayFinancialStatus,
            note : order.note || '',
            discount_code: order.discountCode || '', 
            return_tracking: '', 
            shipping_address : 
            {
                id : order.shippingAddress.id,
                name : order.shippingAddress.name,
                address1 : order.shippingAddress.address1 || '',
                address2 : order.shippingAddress.address2 || '',
                city : order.shippingAddress.city || '',
                province : order.shippingAddress.province || '',
                zip : order.shippingAddress.zip || '',
                country : order.shippingAddress.country || '',
                phone : order.customer.phone || ''
            },
            line_items : order.lineItems.edges.map(processLineItem) , 
            tags : order.tags.join(' '), 
            customer_tags : order.customer.tags.join(' '),
            refunds : []
        }
    }
    catch ( ex) {
        console.log(ex);
    }
}

export const parseLineItems = (order, result) => {


    try {

        console.log(result);

        if (!result) {
            order.moreLineItems = false;
            return;
        }

        const lineItems = result.lineItems.edges;

        order.moreLineItems = result.lineItems.pageInfo.hasNextPage;
        order.lastLiCursor =  lineItems[lineItems.length - 1].cursor;

        const newLineItems = lineItems.map(processLineItem);
        console.log(newLineItems);
        order.line_items = order.line_items.concat(newLineItems); 
    } catch ( ex) {
        console.log(ex);
    }
}
